@font-face {
    font-family: "Intfont";   /*Can be any text*/
    src: local("Alegreya-VariableFont_wght-Regular"),
      url("../../assets/Alegreya-VariableFont_wght.ttf") format("truetype");
}
@font-face {
    font-family: "santa";   /*Can be any text*/
    src: local("PassionConflict-Regular"),
      url("../../assets/PassionsConflict-Regular.ttf") format("truetype");
}

.hpicontainerint {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 500px;
}

.imgmainint{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.santap {
    position: absolute;
    z-index: 1;
    font-size: 160px;
    color:rgb(255, 255, 255);
    text-shadow: 1px 1px 2px #000000;
    font-family: "santa";
}

@media (max-width: 1280px) {
    .santap {
        font-size: 10rem;
    }
    .santapbelow {
        font-size: 1.4rem;
    }
}

@media (max-width: 720px) {
    .santap {
        font-size: 8rem;
    }
    .santapbelow {
        font-size: 1rem;
    }
}