.menucontainer {
    display: flex;
    height: 100vh;
    width: 100vw;
    /* border-top: 2px solid #000000; */
    flex-direction: row;
    align-items: flex-end;
    background:#000000;
  }
  
  .menudiv {
    display:flex;
    height: 100vh;
    width: 50vw;
    justify-content: center;
    align-items: center;
    filter: brightness(0.8);
    text-align: center;
  }
  
  .menulink {
    display: flex;
    height: 100vh;
    width: 50vw;
    justify-content: center;
    align-items: center;
    z-index: 6;
  }

  .menudiv2 {
    display:flex;
    height: 100vh;
    width: 50vw;
    justify-content: center;
    align-items: center;
    filter: brightness(0.8);
    text-align: center;
  }
  
  .menulink2 {
    display: flex;
    height: 100vh;
    width: 50vw;
    justify-content: center;
    align-items: center;
    z-index: 6;
  }
 
.menuimg {
    align-self: flex-end;
    height:100%;
    width: 100%;
    object-fit: cover;
    transition: filter 0.4s ease-in-out;
    /* transform: scale(0.99); */
}

.menulink:hover {
    cursor: pointer;
}

.titleOnImage {
    position: absolute;
    margin-top: -30px;
    color: rgb(255, 255, 255);
    font-size: 3.5rem;
    text-shadow: 1px 1px 2px #000000;
}
/* 
.sentLB {
    position: absolute;
    font-size: 1.35rem;
    text-shadow:2px 2px 2px #000000;
    left : 20;
    bottom: 0;
    margin : 30px;
    margin-bottom: 30px;
    color: wheat;
    z-index: 90;
} */

.menuimg:hover {
  filter: brightness(0.9);
}

@media (max-width: 720px) {
  .menucontainer {
    flex-direction: column;
    justify-content: flex-end;
  }
  .menudiv{
    height: 50vh;
    width: 100vw;
  }
  .menulink {
    height: 50vh;
    width: 100vw;
  }
  .sentLB {
    left: 0;
    font-size: 1rem;
  }
  .titleOnImage {
    font-size: 3rem;
  }
}