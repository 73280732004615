.containercontentint {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    height: 120vh;
    width: 100vw;
    min-height: 700px;
}

.containerdivcontentint {
    display: flex;
    flex-direction: row-reverse;
    height: 120vh;
    width: 100vw;
}

.divcontentintr {
    display: flex;
    height: 120vh;
    width: 50vw;
    background: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 700px;

}
.divcontentintl {
    display: flex;
    height: 120vh;
    width: 50vw;
    background: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 700px;
}

.divfortextoffer {
    display: flex;
    height: 120vh;
    width: 60%;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 15px;
    letter-spacing: 1px;
}

.titleleftcontentint {
    align-self: center;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 40px;
}
.listleftcontentint {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 40px;
}


.contentinttextpara {
    width: 70%;
    font-size: 17px;
}
.contentinttextparabold {
    width: 70%;
    font-size: 17px;
    font-weight: 700;
}
@media (max-width:1280px) {
    .containercontentint {
        display: flex;
        flex-flow: column;
        height: 120vh;
        width: 100vw;
        min-height: 1400px;
    }
    .containerdivcontentint {
        flex-direction: column-reverse;
        justify-content: center;
        height: 160vh;
    }
    .divcontentintr {
        height: 120vh;
        width: 100vw;
    }
    .divcontentintl {
        height: 100vh;
        width: 100vw;
    }
    .divfortextoffer {
        width: 90%;
        font-size: 15px;
    }
    .contentinttextpara{
        font-size: 18px;
    }
    .contentinttextparabold{
        font-size: 18px;
    }
}
@media (max-width:720px) {
    .contentinttextpara {
        font-size: 15px;
    }
    .contentinttextparabold {
        font-size: 15px;
    }
    .divfortextoffer {
        width: 90%;
        font-size: 14px;
    }
}