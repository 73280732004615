@font-face {
    font-family: "Intfont";   /*Can be any text*/
    src: local("Alegreya-VariableFont_wght-Regular"),
      url("../../assets/Alegreya-VariableFont_wght.ttf") format("truetype");
}

.profilecontainer {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    min-height: 500px;
}
.titlediv{
    width: 70%;
    height: 20%;
    text-align: center;
    margin-top: 2%;
    position: absolute;
    font-size: 40px;
    color: #121212;
    z-index: 1;
    font-family: "Intfont";
}
.imgbg2 {
    height: 50%;
    width: 50%;
    left:0;
    filter: blur(7px);
    position: absolute;
    z-index: -1;
    min-height: 500px;
    animation : bgimg 3s alternate infinite;
}

.profiledivimg {
    display: flex;
    height: 100%;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
}
.profiledivright {
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 40%;
}
.profileimg {
    margin-top:40px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.titleimgright {
    font-size: 0px;
    font-weight: 600;
}

.textimgright {
    font-size: 0px;
}

.profiledivleft {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    height: 30%;
    width: 20%;
}
.profileimgleft {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.titleimgleft {
    font-size: 15px;
    font-weight: 600;
}

.textimgleft {
    font-size: 14px;
}
@keyframes bgimg {
    0% {
        transform : translateX(0%);
        transform: scale(1.1);
    }
    100% {
        transform : translateX(10%);
        transform: scale(1.15);
    }
}

@media (max-width: 1280px) {
    .titlediv {
        font-size: 25px;
        width: 80%;
    }
    .profiledivimg {
        justify-content: space-around;
        align-items: center;
    }
    .textimgleft {
        font-size: 14px;
    }
}

@media (max-width: 720px) {
    .titlediv {
        width: 80%;
        font-size: 20px;
        font-weight: 600;
        margin-top: 6%;
    }
    .imgbg2 {
        display: none;
    }
    .profiledivimg {
        justify-content: center;
        align-items: center;
    }
    .profiledivleft {
        display: none;
    }

    .profiledivright {
        height: 50%;
        width: 90%;
    }
    .titleimgright {
        font-size: 13px;
    }
    .textimgright {
        font-size: 13px;
    }
    .profileimg {
        margin-top: 0px;
    } 
}
@media (min-width: 1920px) {
    .profileimg {
        width:200%;
        object-fit: cover;
        margin-top: 0px;
    }
}