.btogcontainergar {
    display: flex;
    height: 22vh;
    width: 100vw;
    background: rgb(41, 41, 41);
    justify-content: center;
    align-items:center;
    transition: all 0.5s ease;
    min-height: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.textbtogcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18vh;
    width: 50vw;
    background: rgba(0, 0, 0, 0);
    transition: all 0.4s;
}
.buttontogtext {
    font-size: 4rem;
    color: wheat;
}

.btogcontainergar:hover{
    cursor: pointer;
    background: #1f3327;
}

.textbtogcontainer:hover {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    letter-spacing: 10px;
}

@media (max-width:1280px) {
    .textbtogcontainer {
        width: 60vw;
    }
    .buttontogtext {
        font-size: 3rem;
    }
}
@media (max-width:720px) {
    .textbtogcontainer {
        width: 70vw;
    }
    .buttontogtext {
        font-size: 2rem;
    }
}