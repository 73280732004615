@font-face {
    font-family: "Intfont";   /*Can be any text*/
    src: local("Alegreya-VariableFont_wght-Regular"),
      url("../../assets/Alegreya-VariableFont_wght.ttf") format("truetype");
}
@font-face {
    font-family: "ivars";   /*Can be any text*/
    src: local("Niconne-Regular"),
      url("../../assets/Niconne-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "santa";  
    src: local("PassionConflict-Regular"),
      url("../../assets/PassionsConflict-Regular.ttf") format("truetype");
}


.hpicontainergar {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 500px;
}

.imgmaingar{
    height: 100%;
    width: 100%;
    filter: contrast(1.2);
    object-fit: cover;
}

.ivarsp {
    position: absolute;
    z-index: 1;
    font-size: 100px;
    color:rgb(255, 255, 255);
    text-shadow: 2px 1px 4px #000000;
    font-family: "Intfont";
}


@media (max-width: 1280px) {
    .ivarsp {
        font-size: 8rem;
    }
    .ivarspbelow {
        font-size: 1.6rem;
    }
}

@media (max-width: 720px) {
    .ivarsp {
        font-size: 6rem;
    }
    .ivarspbelow {
        font-size: 1.2rem;
    }
}