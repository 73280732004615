.divgallerycontainer {
    overflow: hidden;
    background: #000;
}

.masonrycontainer {
    overflow: hidden;
    margin-top: 10vh;
}

.contforoverlay {
   display: flex;
}

.imgfrommas {
    position: relative;
}

.imgfrommas:hover {
    cursor: pointer;
}

body.active-modalgal {
    overflow-y: hidden;
}
.modalgal, .overlaymod {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlaymod{
    background: rgba(49,49,49,0.8);
}
.modal-contentgal {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #0f010100;
    border-radius: 5px;
    z-index: 1;
}
.image-modalgal{
    height:50vh;
    width: 40vw;
    border-radius: 8px;
    object-fit: cover;
    
}
.close-modalgal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50px;
    width:50px;
    border : transparent;
    margin: 5px;
    border-radius: 30px;
    background: rgba(56, 51, 51, 0.856);
    transition: all 1s ease-in-out;
}

.close-modalgal:hover {
    cursor: pointer;
    transform: rotate(360deg);
}
.xofthebuttongal {
    color: wheat;
    text-shadow: 1px 1px 2px #000;
}
.indexmoddec {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50px;
    width:30px;
    top:45%;
    border : transparent;
    margin: 5px;
    background: rgba(56, 51, 51, 0.856);
    transition: all 1s ease-in-out;
}
.indexmodinc {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50px;
    width:30px;
    top:45%;
    right: 30%;
    border : transparent;
    margin: 5px;
    background: rgba(56, 51, 51, 0.856);
    transition: all 1s ease-in-out;
}

.indexmoddec:hover {
    cursor: pointer;
}
.indexmodinc:hover {
    cursor: pointer;
}

.indexmodnumb {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;  
    top:65%;
    right: 50%;
    border : transparent;
    margin: 5px;
    color:white;
    text-shadow: 1px 1px 1px #000;
}
@media (max-width: 900px) {
    .image-modalgal {
        width: 70vw;
    }
    .indexmodinc {
        right: 15%;
    }
  }