.headerdiv {
    display: flex;
    justify-content: space-between;
    height: 13vh;
    width: 100vw;
    z-index: 99;
    position: fixed;
    overflow: hidden;
    min-height: 50px;
    background-color: #8b898933
}

.logoContainer {
    display: flex;
    height: 100%;
    width: 10%;
    justify-content: flex-start;
    align-items: center;
    margin-left: 25px;
    filter: contrast(1);

}
.logo {
    transition: filter 0.7s ease-out;
}
.logo:hover {
    filter: contrast(3);
    cursor: pointer;
}

.divheaderlink {
    display:flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 35%;
    margin-left: 125px;
}

.titleG {
    color: #FFF;
    font-size: 22px;
    text-shadow: 1px 1px 1px #000000;
    transition: opacity 0.4s ease-out;
}

.titleG:hover {
    opacity: 0.4;
    cursor: pointer;
}

.divlangagepicker {
    display: flex;
    width: 20vw;
    height: 100%;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-shadow: 1px 1px 2px #000000;
}

.pluslpicker {
    color: wheat;
    transition: 1s ease;
    
}

.selectlangagediv {
    display: flex;
    flex-direction: row;
    transition: all 0.4s ease-in-out;
    margin-left: 10px;
}

.divlangagepicker:hover .pluslpicker {
    transform: rotate(360deg);
}

.firsttomove {
    opacity: 0;
    margin-right: 10px;
    transition: all 0.8s;
}
.secondtomove {
    opacity: 0;
    margin-right: 10px;
    transition: all 1.6s;
}
.divlangagepicker:hover .firsttomove {
    cursor: pointer;
    opacity: 1;
}
.divlangagepicker:hover .secondtomove {
    cursor: pointer;
    opacity: 1;
}


@media (max-width: 1280px) {
    .divheaderlink {
        display: none;
     }
     .divlangagepicker {
        font-size: 18px;
     }
}
@media (max-width: 720px) {
    .headerdiv {
        height: 10vh;
    }
    .logo:hover {
        opacity: 1;
    }
}
@media(max-height:300px) {
    .headerdiv {
        display: none;
    }
}