.App {
  height: "auto";
  width: "auto";
}
.imageIvars {
  display:inline-block;
  max-height: 33%;
  width: 33%;
  transition: width 2s 
}

.imageIvars:hover {
  opacity: 0.8;
  cursor: pointer;
  width: 50%;
  height:33%
  
}