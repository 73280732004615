.barsepdivint {
    display: flex;
    height: 10vh;
    margin-top:13vh;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: rgb(49, 49, 49);
    min-height: 100px;
}

.barseptitle {
    color: wheat;
    font-size: 29px;
    text-shadow: 1px 1px 2px #000;
}

@media (max-width:720px) {
    .barsepdivint {
        margin-top: 10vh;
    }
    .barseptitle {
        font-size: 25px;
    }
}

@media (max-height:300px) {
    .barsepdivint {
        margin-top: 0vh;
    }
}