.containerofdivcarrgar {
    height: 100vh;
    width: 100vw;
    background: #1f3327;
    overflow: hidden;
    min-height: 500px;
}
.containerCarr {
    display:flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    width: 400vw;
    min-height: 500px;
    animation: mesImages 33s linear infinite;
}

.vrai {
    height: 100vh;
    width: 200vw;
    min-height: 500px;
    display: flex;
}
.imagecarr {
    height: 40vh;
    width:20vw;
    min-height: 200px; 
    transition: transform 1s;
    border: 4px solid #9c8787;
    border-radius: 5px;
    object-fit:cover;
}

.imagecarr2 {
    height: 30vh;
    width: 20vw;
    min-height: 150px;
    border: 2px solid #9c8787;
    transition: transform 1s;
    border-radius: 5px;
    object-fit:cover;

}

.imagecarr:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.imagecarr2:hover {
    cursor: pointer;
    transform: scale(1.1);
}

@keyframes mesImages {
    0% {
        transform: translateX(0);

    }
    100% {
        transform: translateX(-100%);
    }
}


/* modal css */
body.active-modal {
    overflow-y: hidden;
}
.modalgar, .overlaygar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlaygar {
    background: rgba(49,49,49,0.8);
}
.modal-contentgar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #0f010100;
    border-radius: 5px;
    z-index: 1;
}
.image-modalgar{
    height:50vh;
    width: 40vw;
    border-radius: 8px;
}
.close-modalgar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50px;
    width:50px;
    border : transparent;
    margin: 5px;
    border-radius: 30px;
    background: rgba(56, 51, 51, 0.856);
    transition: all 1s ease-in-out;
}

.close-modalgar:hover {
    cursor: pointer;
    transform: rotate(360deg);
}
.xofthebuttongar {
    color: wheat;
    text-shadow: 1px 1px 2px #000;
}

@media (max-width:1280px) {
    .vrai {
        width: 300vw;
    }
    .imagecarr {
        width: 22.5vw;
    }
    .imagecarr2 {
        width: 30vw;
    }
}
@media (max-width:720px) {
    .containerofdivcarr {
        height: 80vh;
    }
    .containerCarr {
        height: 80vh;
        animation: mesImages 30s linear infinite;
    }
    .vrai {
        height: 80vh
        ;
    }
    .vrai {
        width: 500vw;
    }
    .imagecarr {
        width: 42.5vw;
    }
    .imagecarr2 {
        width: 50vw;
    }
    .image-modal {
        width: 90vw;
    }
    .imagecarr:hover {
        transform: scale(1);
    }
    .imagecarr2:hover{
        transform: scale(1);
    }
}